@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../../../node_modules/locomotive-scroll/dist/locomotive-scroll.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #1e1e1e;
  overflow: hidden;
}
h1 {
  line-height: 1.15;
}

h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 14px;
  font-weight: 400;
}

.main-title,
.section-title {
  display: block;
}


@media (min-width: 576px) {
  .main-title,
  .section-title {
    font-size: calc(40px + 110*(100vw - 576px)/1024);
  }

  h1 {
    font-size: calc(40px + 50*(100vw - 576px)/1024);
    margin-bottom: calc(16px + 16*(100vw - 576px)/1024);
    margin-top: calc(24px + 24*(100vw - 576px)/1024)
  }

  h2 {
    font-size: calc(30px + 26*(100vw - 576px)/1024);
    margin-bottom: calc(8px + 24*(100vw - 576px)/1024);
    margin-top: calc(16px + 32*(100vw - 576px)/1024);
  }

  h3 {
    font-size: calc(24px + 16*(100vw - 576px)/1024);
    margin-bottom: calc(8px + 24*(100vw - 576px)/1024);
    margin-top: calc(16px + 32*(100vw - 576px)/1024)
  }


  h4 {
    font-size: calc(24px + 8*(100vw - 576px)/1024);
    margin-bottom: calc(8px + 24*(100vw - 576px)/1024);
    margin-top: calc(16px + 16*(100vw - 576px)/1024)
  }

  h5 {
    font-size: calc(16px + 8*(100vw - 576px)/1024);
    margin-bottom: calc(8px + 8*(100vw - 576px)/1024);
    margin-top: calc(8px + 8*(100vw - 576px)/1024)
  }
}


@media (min-width: 1024px) {
  .card-sm {
    max-width: 32vw;
    min-width: 32vw;
  }
  .card-lg {
    min-width: 44vw;
  }
}

@media (min-width: 1600px) {
  h1 {
    font-size: 5.625vw;
    margin-bottom: 32px;
    margin-top: 48px;
  }

  h2 {
    font-size: 3.5vw;
    margin-bottom: 32px;
    margin-top: 48px;
  }

  h3 {
    font-size: 2.5vw;
    margin-bottom: 32px;
    margin-top: 48px
  }

  h4 {
      font-size: 2vw;
      margin-bottom: 32px;
      margin-top: 32px
  }

  h5 {
      font-size: 1.5vw;
      margin-bottom: 16px;
      margin-top: 16px
  }
}

.banner-title {
  @media (min-width: 1152px) and (max-height:970px) {
    font-size: 6.5vw;
  }

  @media (min-width: 2400px) and (max-height:1120px) {
    font-size: 6.5vw;
  }
}

.background-animate {
  background-size: 400%;

  -webkit-animation: FadeGradientEffect 10s ease infinite;
  -moz-animation: FadeGradientEffect 10s ease infinite;
  animation: FadeGradientEffect 10s ease infinite;
}

@keyframes FadeGradientEffect {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.slider__word {
  transform: translateY(100%);
  animation: SlideWord 6s linear infinite;
}

@keyframes SlideWord {
  0% {
    transform: translateY(100%);
    opacity: 0.1;
  }
  15% {
    transform: translateY(0);
    opacity: 1;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }
  45% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0.1;
  }
}

#header .inner, 
#footer .inner, 
section .inner {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  
  @media (min-width: 576px) {
    padding-left: calc(20px + 70*(100vw - 576px) / 1024);
    padding-right: calc(20px + 70*(100vw - 576px) / 1024);
  }
}
section .inner {
  @media (min-width: 1024px) {
    min-height: 500px;
  }
}